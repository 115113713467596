<template>
  <div class="header-visual-home">
    <div class="header-visual-home__background" />
    <div class="header-visual-home__overlay" />
  </div>
</template>

<script>
export default {
  name: 'HeaderVisualHome',
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.header-visual-home {
  $self: &;
  position: relative;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  top: 0;
  right: 0;
  height: auto;

  @include lg-up {
    width: 51%;
  }

  @include lg-up {
    position: absolute;
    left: 50%;
    margin-left: 0.75rem;
    margin-right: unset;
    height: 100%;
    width: calc(50% - 0.75rem);
  }

  &__background {
    position: absolute;
    top: calc(-80px - env(safe-area-inset-top));
    left: 35vw;
    height: 400px;
    width: 1384px;
    background: url('/assets/img/bg-header-shape.svg') no-repeat top left;
    background-size: auto 100%;
    padding-top: 70%;

    @include lg-up {
      top: -200px;
      left: 0;
      padding-top: 95%;
      width: 1384px;
      height: 1000px;
    }
  }

  // Found on checkout page
  &__overlay {
    background: radial-gradient(
      ellipse at top right,
      rgba(255, 190, 98, 0.85),
      rgba(255, 181, 73, 1)
    );
  }
}
</style>
